<template>
 <div class="max-width main-div">
     <!--<div id="shousuo">-->
         <!--<div ><el-input v-model="input" placeholder="请输入内容"></el-input></div>-->
         <!--<div style="padding-left: 1rem"><el-button type="primary" @click="selectHandler">搜索</el-button></div>-->
     <!--</div>-->

     <div id="selecthire " >
         <el-radio-group fill="#f66320" v-model="jobType"  text-color="#ffffff" @change="radioClickHandle">
             <div class="flex-row row-center hirebuttons" >
                 <div><el-radio-button id="hireradio1" label=6><img v-if="jobType!=6" src="../assets/images/hire/icon_qb_o.png"><img v-if="jobType==6" src="../assets/images/hire/icon_qb_w.png"><span :style="{color:jobType==6?'#ffffff':'#f66320'}">全部</span></el-radio-button></div>
                 <div><el-radio-button id="hireradio2" label=1><img v-if="jobType!=1" src="../assets/images/hire/icon_cx_o.png"><img v-if="jobType==1" src="../assets/images/hire/icon_cx_w.png"><span :style="{color:jobType==1?'#ffffff':'#f66320'}">程序</span></el-radio-button></div>
                 <div><el-radio-button id="hireradio3" label=2><img v-if="jobType!=2" src="../assets/images/hire/icon_ms_o.png"><img v-if="jobType==2" src="../assets/images/hire/icon_ms_w.png"><span :style="{color:jobType==2?'#ffffff':'#f66320'}">美术</span></el-radio-button></div>
                 <div><el-radio-button id="hireradio4" label=3><img v-if="jobType!=3" src="../assets/images/hire/icon_yy_o.png"><img v-if="jobType==3" src="../assets/images/hire/icon_yy_w.png"><span :style="{color:jobType==3?'#ffffff':'#f66320'}">运营</span></el-radio-button></div>
                 <div><el-radio-button id="hireradio5" label=4><img v-if="jobType!=4" src="../assets/images/hire/icon_ch_o.png"><img v-if="jobType==4" src="../assets/images/hire/icon_ch_w.png"><span :style="{color:jobType==4?'#ffffff':'#f66320'}">策划&QA</span></el-radio-button></div>
                 <div><el-radio-button id="hireradio6" label=5><img v-if="jobType!=5" src="../assets/images/hire/icon_zn_o.png"><img v-if="jobType==5" src="../assets/images/hire/icon_zn_w.png"><span :style="{color:jobType==5?'#ffffff':'#f66320'}">其他</span></el-radio-button></div>
             </div>
         </el-radio-group>
     </div>

     <div id="hirecontent">
         <div v-for="item in this.jobTable" :key="item.id" @click="clickBoxHandler(item)">
             <label class="hiretitle">{{item.workName}}</label>
             <label class="hirecontent">{{switchTimeType(item.timeType)+"|"+switchStudyType(item.studyType)+"|招聘"+item.people+"人"}}</label>
         </div>
     </div>
     <div id="hirepage" style="padding-top: 2rem;padding-bottom: 2rem">
         <el-pagination  @current-change="changePageHandle"
                 layout="prev, pager, next"
                 :page-size="pageConfig.size"
                 :total="pageConfig.total">
         </el-pagination>
     </div>


     <!--弹窗位置-->
     <el-dialog   :visible.sync="dialogInfoVisible" center>
         <div>
             <h1>{{hireItem.workName}}</h1>
         </div>
         <div>
             <span style="color: silver">{{switchWorkType(hireItem.workType)}}</span>
         </div>
         <div v-html="hireItem.content">
            {{hireItem.content}}
         </div>
         <div style="width: 100%; justify-content: right;margin-right: 1rem;display: flex">
             <!--<el-button  @click="initHireHandler(hireItem.id)">简历投递</el-button>-->
             <!-- v-clipboard不管编辑器问题吧 -->
             <el-button  v-clipboard:copy="hireItem.email"
                         v-clipboard:success="copySuccessHandler">复制投递邮箱</el-button>
         </div>
     </el-dialog>

     <!--投递弹窗位置-->
     <!--<el-dialog class="hireTitleDialog" :visible.sync="dialogInfromVisible" >-->
         <!--<div slot="title" class="header-title" style="background-color:#F0841A;padding: 0">-->
             <!--<div style="padding:1rem 2rem;font-size: 2rem;font-family: 微软雅黑;font-weight: bold">投递简历</div>-->
         <!--</div>-->
         <!--<div style="margin-right:0; text-align: left">-->
             <!--<span>姓名：</span>-->
             <!--<el-input v-model="deliver.name" placeholder="请输入内容" style="width: 50%"></el-input>-->
         <!--</div>-->
         <!--<div style="margin-top: 1rem;margin-left: 0;text-align: left">-->
             <!--<span>手机：</span>-->
             <!--<el-input v-model="deliver.phone" placeholder="请输入内容" style="width: 50%"></el-input>-->
         <!--</div>-->
         <!--<div style="display: flex;flex-direction: row;margin-top: 1rem">-->
             <!--<span>验证码：</span>-->
             <!--<slide-verify :l="42"-->
                           <!--:r="10"-->
                           <!--:w="310"-->
                           <!--:h="155"-->
                           <!--slider-text="向右滑动"-->
                           <!--@success="verifySuccessHandler"-->
                           <!--@fail="verifyFailHandler"-->
                           <!--@refresh="verifyRefreshHandler"-->
                           <!--ref ="verifyCode"-->
             <!--&gt;</slide-verify>-->
         <!--</div>-->
         <!--<div style="display: flex;flex-direction: row;margin-top: 1rem">-->
             <!--<span>上传简历：</span>-->
             <!--<el-upload-->
                     <!--class="upload-demo"-->
                     <!--:accept="'.doc,.docx,.pdf'"-->
                     <!--multiple-->
                     <!--:action="this.$uploadFileUrl+'/upload/upload'"-->
                     <!--:before-upload="beforeUplaod"-->
                     <!--:on-remove="handleRemove"-->
                     <!--:before-remove="beforeRemove"-->
                     <!--:on-success="successHandler"-->
                     <!--:limit="1"-->
                     <!--:on-exceed="handleExceed"-->
                     <!--ref = "upupload"-->
                     <!--&gt;-->
                 <!--<el-button size="small" type="primary">点击上传简历</el-button>-->
                 <!--<div slot="tip" class="el-upload__tip">只能上传word/ppt/excel文件</div>-->
             <!--</el-upload>-->
         <!--</div>-->
         <!--<div style="width: 100%; justify-content: center;margin-right: 1rem;display: flex">-->
             <!--<el-button type="warning"  @click="hireHandler">投递简历</el-button>-->
         <!--</div>-->
     <!--</el-dialog>-->
 </div>
</template>

<script>
    import EventNameEnum from "../enum/EventName";
    import Vcode from "vue-puzzle-vcode";
    export default {
        name: "Hire",
        created(){
            this.$event.$emit(EventNameEnum.changeBar,true);
            if(this.$route.query.type){
                this.jobType = `${this.$route.query.type}`;
            }
            this.fullIndex(1);
            this.fullTabel(1);
        },
        components: {
            Vcode,
        },
        data() {
            return {
                isSelect:false,
                input: '',
                select:"",
                jobType:"6",
                radioOSrcArr:["icon_qb_o.png","icon_cx_o.png","icon_ms_o.png","icon_yy_o.png","icon_ch_o.png","icon_zn_o.png"],
                radioWSrcArr:["icon_qb_w.png","icon_cx_w.png","icon_ms_w.png","icon_yy_w.png","icon_ch_w.png","icon_zn_w.png"],
                dialogInfoVisible: false,
                dialogInfromVisible: false,
                pageConfig:{
                    index:1,
                    size:9,
                    total:500,
                },
                jobTable:[],
                hireItem:{
                    content:"",
                    workName:"",
                    workType:1,
                    email:"我叫email"
                },
                deliver:{
                    name:"",
                    phone:"",
                    docUrl:"",
                    jobId:0,
                    uuid:"",
                    verifyCode:undefined,
                },
                autoDelete:1,
                firstOpen:true,
            }
        },
        methods:{
            copySuccessHandler(){
                this.$message.success("复制成功")
            },
            initVerifyCode(){
                this.$http.get("resume/getCode",{},{}).then((res) => {
                    if(res.body.code==200){
                        let data =res.body.data;
                        this.deliver.uuid = data.uuid;
                        this.deliver.verifyCode = data.verifyCode;
                        this.$message.success("验证成功");
                    }else{
                        // console.warn(res.body.message);
                        this.$message.error("服务器异常，请刷新验证码重试")
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            verifyRefreshHandler(){
                this.$message.success("刷新成功");
                this.uuid="";
                this.verifyCode = undefined;
            },
            verifyFailHandler(){
                this.$message.error("验证失败");
            },
            verifySuccessHandler(){
               this.initVerifyCode();
            },
            radioClickHandle:function (e) {
                //修改刷新当前目录目录
                // console.log("radio:"+e)
                this.isSelect = false;
                this.pageConfig.index = 1;
                this.fullIndex(1);
                this.fullTabel(1);
            },
            changePageHandle:function (e) {
                this.pageConfig.index = e;
                this.fullTabel(this.isSelect?2:1);
            },
            //
            /**
             * @desc 1是选择的 2是查询的
             */
            fullTabel(type){
                let url;
                let obj;
                switch (type) {
                    case 1:
                        url = 'job/getJobByIndex';
                        obj = {type:this.jobType,index:this.pageConfig.index}
                        break;
                    case 2:
                        url = 'job/getSelectJobByIndex';
                        obj = {select:this.select,index:this.pageConfig.index}
                        break;
                }
                this.$http.post(url,obj ,{}).then((res) => {
                    if(res.body.code==200){
                        this.jobTable = res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            fullIndex(type){
                let url;
                let obj;
                switch (type) {
                    case 1:
                        url = 'job/getTotal';
                        obj = {params: {type:this.jobType}}
                        break;
                    case 2:
                        url = 'job/getTotalBySelect';
                        obj = {params: {select:this.select}}
                        break;
                }
                this.$http.get(url,obj,{}).then((res) => {
                    if(res.body.code==200){
                        this.pageConfig.total=res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            selectHandler(){
                this.isSelect = true;
                this.select = this.input;
                this.pageConfig.index = 1;
                this.fullIndex(2);
                this.fullTabel(2);
            },
            clickBoxHandler(item){
                this.hireItem = item;
                this.dialogInfoVisible = true;
            },
            initHireHandler(id){
                this.deliver.name = "";
                this.deliver.phone = "";
                this.deliver.docUrl = "";
                this.deliver.jobId = id;
                this.deliver.uuid = "";
                this.deliver.verifyCode = undefined;
                this.dialogInfoVisible = false;
                if(this.firstOpen){
                    this.firstOpen = false;
                }else{
                    this.$refs.verifyCode.reset();
                    this.$refs.upupload.clearFiles();
                }
                this.dialogInfromVisible=true;
            },
            // hireHandler(){
            //     if(!this.deliver.verifyCode||!this.deliver.uuid){
            //         this.$message.error("请先验证");
            //         return;
            //     }
            //     if(this.deliver.name == ""){
            //         this.$message.error("请填写名字");
            //         return;
            //     }
            //     if(this.deliver.phone == ""){
            //         this.$message.error("请填写电话");
            //         return;
            //     }
            //     if(this.deliver.docUrl == ""){
            //         this.$message.error("请上传文件");
            //         return;
            //     }
            //     this.$http.post('resume/setResume', this.deliver,{}).then((res)=>{
            //         if(res.body.code==200){
            //             this.$message.success("上传成功");
            //             this.dialogInfromVisible = false;
            //         }else if(res.body.code == 2) {
            //             let errorArr = JSON.parse(res.body.message);
            //             let msg = "";
            //             for(let error of errorArr){
            //                 let m = error[Object.keys(error)[1]];
            //                 msg+= msg==""?m:","+m;
            //             }
            //             this.$message.error(msg);
            //         }else{
            //             this.$message.error("上传失败")
            //         }
            //     })
            // },
            switchWorkType(value){
                switch (value) {
                    case 1:return "程序";
                    case 2:return "美术";
                    case 3:return "游戏运营";
                    case 4:return "策划&QA";
                    case 5:return "职能";
                    case 6:return "其他";
                }
            },
            switchStudyType(value){
                switch (value) {
                    case 1:return "大专";
                    case 2:return "本科";
                    case 3:return "本科及以上"
                    case 4:return "研究生";
                    case 5:return "学历不限";
                }
            },
            switchTimeType(value){
                switch (value) {
                    case 1:return "一年以内";
                    case 2:return "1-3年";
                    case 3:return "3-5年";
                    case 4:return "5年以上";
                    case 5:return "经验不限";
                }
            },

            handleRemove(file, fileList) {
                if( this.autoDelete===2){
                    this.autoDelete = 1;
                }else {
                    this.$message.success("文件删除成功")
                }

            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                if( this.autoDelete===2)return true;
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            beforeUplaod(file){
                let typeIsTrue=["application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].indexOf(file.type)!=-1;
                if(!typeIsTrue){
                    this.$message.error("请上传doc、docx、pdf");
                    this.autoDelete = 2;
                    return false;
                }
            },
            successHandler(response,file,fileList){
                if(!response.code||response.code!==200){
                    fileList.splice(fileList.indexOf(file),1);
                    this.$message.error("上传失败");
                }else {
                    this.deliver.docUrl = response.data.url;
                }
            }
        }
    }
</script>

<style>
    .main-div{
        /*padding-top:4rem;*/
        /*background-color: #9c26b0;*/
        /*justify-content: center;*/
        /*display: flex;*/
        /*flex-direction: column;*/
    }
    .max-width{
      width: 100%;
    }

    #shousuo{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #selecthire .el-radio-group{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #shousuo div .el-input__inner {
        width: 29.2rem;
        height: 3.4rem;
        border: .1rem solid #f66320;
    }
    #shousuo div .el-button--primary{
        font-size: 1.6rem;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        font-family: "微软雅黑";
        width: 7.2rem;
        height: 3.4rem;
        line-height: 3.4rem;
        background-color: #f66320;
        border: 0rem solid #f66320;
        padding: 0rem;
    }

    .hirebuttons div{
        height: 6.4rem;
        padding-top: 3rem;
        padding-right: 3rem;
    }

    .hirebuttons div .el-radio-button__inner{
        height: 6.4rem;
        padding-top: 0;
        border: .1rem solid #f66320 !important;
    }

    .hirebuttons div .el-radio-button__inner img{
        padding-right: 1rem;
    }

    .hirebuttons div .el-radio-button__inner span{
        display: inline-block;
        line-height: 6.4rem;
        font-family: "微软雅黑";
        font-weight: bold;
        font-size: 1.9rem;
        color:#f66320;
        text-align: center;
    }

    #hirecontent{
        height: 46rem;
        width: 120rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 相当于 1fr 1fr 1fr */
        grid-template-rows: repeat(3, 1fr); /* fr单位可以将容器分为几等份 */
        /*grid-gap: 1%; !* grid-column-gap 和 grid-row-gap的简写 *!*/
        grid-auto-flow: row;
        /*background-color: #39b54a;*/
        padding-top: 6rem;
        margin: 0 auto;
    }

    #hirecontent div{
        height: 11.2rem;
        width: 35.8rem;
        border:.1rem solid #f66320;
    }

    #hirepage .el-pagination .el-pager li.active{
        background-color:#f66320;
        color:#ffffff;
    }

    .hiretitle{
        display: block;
        padding-top: 2rem;
        padding-left: 2rem;
        font-family: "微软雅黑";
        font-size: 2.1rem;
        font-weight: bold;
        color: #f66320;
        text-align: left;
    }

    .hirecontent{
        display: block;
        padding-top: 2rem;
        padding-left: 2rem;
        font-family: "微软雅黑";
        font-size: 1.6rem;
        color: #939393;
        text-align: left;
    }

    .hireTitleDialog .el-dialog__header{
        padding: 0 !important;
        text-align: center;
    }
</style>

<style scoped>

</style>
