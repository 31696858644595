<template>
    <div class="main">
            <div class="main-width one-part">
                <router-link to="/">首页</router-link>>
                <router-link to="/news">新闻资讯</router-link>>
                <span>正文</span>
            </div>
        <div class="dashedline auto-max-width"></div>
        <div class="two-part">
             <h3>{{artItem.title}}</h3>
             <span>{{"发布时间："+dateFormat(artItem.createAt)}}</span>
        </div>
        <div class="dashedline main-width"></div>
        <div class="main-width three-part" v-html="artItem.content">
            {{artItem.content}}
        </div>
        <div class="dashedline main-width"></div>
        <div class="share-part">
            <!--<span style="display: inline-block;line-height:3rem;">分享:</span>-->
            <!--<img src="../assets/images/newsArticle/icon_qqz.png" @click="shareToQQ">-->
            <!--<img src="../assets/images/newsArticle/icon_wx.png" @click="weixin">-->
        </div>
        <div class="page-part">
            <div>
                <span>上一篇:</span>
                <span v-if="!upItem">没有了</span>
                <span class="UpDownText hoverHand" @click="changeArt(upItem.id)" v-if="!!upItem">{{upItem?upItem.name:""}}</span>
            </div>
            <div>
                <span>下一篇:</span>
                <span v-if="!downItem">没有了</span>
                <span class="UpDownText hoverHand" @click="changeArt(downItem.id)" v-if="!!downItem">{{downItem?downItem.name:""}}</span>
            </div>
        </div>
        <div class="backtop hoverHand" @click="topFunction"><img src="../assets/images/newsArticle/backTop.png"></div>
        <el-dialog
                title="微信分享"
                :visible.sync="twoCodeVisible"
                width="30%"
        >
            <div ref="qrcodeCanvas">
               <canvas ref="myCanvas">

               </canvas>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import EventNameEnum from "../enum/EventName";
    const QrCodeWithLogo = require("qr-code-with-logo");

    export default {
        name: "NewsArticle",
        created(){
            this.$event.$emit(EventNameEnum.changeBar,false);
            this.initArt(this.$route.query.id);
        },
        data(){
            return{
                artItem:{
                    audioUrl: "",
                    content: "",
                    createAt: "2021-11-20T07:46:13.580Z",
                    id: 3,
                    imgUrl: "",
                    introduction: "让我试试看",
                    title: "giegie",
                    type: 3,
                    updateAt: "2021-11-20T07:46:13.580Z",
                    videoUrl: "",
                    vote: 0
                },
                upItem:undefined,
                downItem:undefined,
                twoCodeVisible:false,
            }
        },
        methods:{
            shareToQQ:function () {
                let url = "https://connect.qq.com/widget/shareqq/index.html";
                let shareUrl = "http://www.zhuque.me/a/lianxiwomen/"//window.location.href;
                let title = "分享标题";
                let summary = "分享显示的内容简介";
                let pics = "图片地址";
                let width = "32";
                let height = "32";
                let allUrl = url + "?url=" + encodeURIComponent(shareUrl) + "&title=" + title + "&summary="
                    + summary + "&pics=" + pics + "&width=" + width + "&height="
                    + height;
                window.open(allUrl);
            },

            /**
             * 微信分享
             * @return {[type]} [description]
             */
            async weixin() {
                this.twoCodeVisible = true;
                setTimeout(()=>{
                    let canvas = this.$refs.myCanvas;
                    QrCodeWithLogo.default.toCanvas({
                        canvas: canvas,
                        content: "testing"+this.$route.query.id,//window.location.href,
                        width: 110,
                        errorCorrectionLevel: 'L',
                        borderSize: 0, //计算模式,
                        margin: 0,
                        // 二维码里放的图标
                        // logo: {
                        //     src: xxxx
                        // }
                    })
                },100)
            },
            topFunction:function() {
                 document.body.scrollTop = 0;
                 document.documentElement.scrollTop = 0;
            },
            initArt(id){
                this.$http.get('news/getArticle',{params:{id:id}},{}).then((res) => {
                    if(res.body.code==200){
                        // console.log(res.body.data);
                        this.artItem=res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                });
                this.upItem=undefined;
                this.downItem=undefined;
                this.$http.get('news/getArticleOther',{params:{id:id}},{}).then((res) => {
                    if(res.body.code==200){
                        // console.log(res.body.data);
                        this.upItem = res.body.data.up;
                        this.downItem = res.body.data.down;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                });
            },
            dateFormat(time){
                let timer = new Date(time);
                return timer.getFullYear()+"-"+(timer.getMonth()+1)+"-"+timer.getDate();
            },
            changeArt(id){
                this.$router.replace({
                    query: {
                        id: id,
                    },
                });
                this.initArt(id);
            }
        }
    }
</script>

<style scoped>
    .main{
        padding-top: 8rem;
    }
    .main-width{
        width: 120rem;
    }
    .one-part{
        margin: 1rem auto;
        font-family: "微软雅黑";
        font-size: 1.6rem;
        color:#727272;
        display: flex;
        flex-direction: row;
    }

    .one-part a{
        text-decoration: none;
        color:#727272;
    }

    .one-part span{
        color:#5696ff;
    }

    .two-part{
        margin: 4rem auto;
        font-family: "微软雅黑";
    }

    .two-part h3{
        font-size: 2.4rem;
        color: #474747;
    }

    .two-part span{
        font-size: 1.4rem;
        color: #adadad;
    }

    .three-part{
        margin: 0 auto;
    }

    .share-part{
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 1.8rem;
        height: 1.5rem;
    }

    .page-part{
        background-color: #f6f6f6;
        height: 10.5rem;
        width: 120rem;
        margin: 0 auto ;
        text-align: start;
        color:#9f9f9f;
        font-size: 1.8rem;
        font-family: "微软雅黑";
        padding-top: 2.8rem;
        padding-left: 1.0rem;
    }

    .UpDownText{
        color: black;
    }

    .hoverHand{
        cursor: pointer;
    }

  .dashedline{
      border-bottom:0.1rem dashed #e5e5e5;
      height: 0.2rem;
      margin-bottom: 0.2rem;
      margin-left: auto;
      margin-right: auto;
  }

  .backtop{
      position: absolute ;
      right: 20rem;
      bottom: 40rem;
  }
    .auto-max-width{
        width:100%;
    }
</style>
