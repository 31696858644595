<template>
    <div class="main">
        <div class="main-width one-part">
            <span>{{declarationName[artItem.type]}}</span>
        </div>
        <div class="dashedline auto-max-width"></div>
        <div class="two-part">
            <h3>{{declarationName[artItem.type]}}</h3>
        </div>
        <div class="dashedline main-width"></div>
        <div class="main-width three-part" v-html="artItem.content">
            {{artItem.content}}
        </div>
        <div class="dashedline main-width"></div>
        <div class="share-part">
            <!--<span style="display: inline-block;line-height:3rem;">分享:</span>-->
            <!--<img src="../assets/images/newsArticle/icon_qqz.png" @click="shareToQQ">-->
            <!--<img src="../assets/images/newsArticle/icon_wx.png" @click="weixin">-->
        </div>

    </div>
</template>

<script>

    import EventNameEnum from "../enum/EventName";

    export default {
        name: "Declaration",
        created(){
            this.$event.$emit(EventNameEnum.changeBar,false);
            this.initArt(this.$route.query.type);
        },
        data(){
            return{
                artItem:{
                    content: "",
                    type: 3,
                },
                upItem:undefined,
                downItem:undefined,
                twoCodeVisible:false,
                declarationName:{
                    1:"法律声明",
                    2:"隐私政策",
                    3:"家长监护"
                }
            }
        },
        methods:{
            initArt(type){
                this.$http.post('declaration/getDeclarationSingle',{type:Number(type)}).then((res) => {
                    if(res.body.code==200){
                        // console.log(res.body.data);
                        this.artItem=res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                });
            },
        }
    }
</script>

<style scoped>
    .main{
        padding-top: 8rem;
    }
    .main-width{
        width: 120rem;
    }
    .one-part{
        margin: 1rem auto;
        font-family: "微软雅黑";
        font-size: 1.6rem;
        color:#727272;
        display: flex;
        flex-direction: row;
    }

    .one-part a{
        text-decoration: none;
        color:#727272;
    }

    .one-part span{
        color:#5696ff;
    }

    .two-part{
        margin: 4rem auto;
        font-family: "微软雅黑";
    }

    .two-part h3{
        font-size: 2.4rem;
        color: #474747;
    }

    .two-part span{
        font-size: 1.4rem;
        color: #adadad;
    }

    .three-part{
        margin: 0 auto;
    }

    .share-part{
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 1.8rem;
        height: 1.5rem;
    }

    .dashedline{
        border-bottom:0.1rem dashed #e5e5e5;
        height: 0.2rem;
        margin-bottom: 0.2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .auto-max-width{
        width:100%;
    }
</style>
