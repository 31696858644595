<template>
  <div class="about flex-col">
    <div class="about-guide-bar">
       <a href="#item1">公司介绍</a>
       <a href="#item2">公司文化</a>
       <a href="#item3">福利待遇</a>
       <a href="#item4">公司环境</a>
    </div>

    <div id="item1" class="flex-row row-center">
      <div class="main-content-width">
        <div class="title-img">
         <img src="../assets/images/about/title_gsjs.png"/>
        </div>
        <div class = "row-center"style="padding-bottom: 4rem" v-html="webConfig.introduction">
          <!--<p class="company-introduction-text" >-->
          {{webConfig.introduction}}
          <!--</p>-->
        </div>
      </div>
    </div>

    <div id="item2" class="flex-row row-center background-color-gray">
      <div class="main-content-width">
        <div class="title-img">
          <img src="../assets/images/about/title_qywh.png"/>
        </div>
        <div class="flex-row qywh">
          <div>
            <img :src="webConfig.cultureImg1"/>
          </div>
          <div>
            <img :src="webConfig.cultureImg2"/>
          </div>
          <div>
            <img :src="webConfig.cultureImg3"/>
          </div>
          <div>
            <img :src="webConfig.cultureImg4"/>
          </div>
        </div>
      </div>
    </div>

    <div id="item3" class="flex-row row-center">
      <div class="main-content-width">
        <div class="title-img">
          <img src="../assets/images/about/title_fldy.png"/>
        </div>
        <div class="fldy">
          <div class="flex-row">
            <div class="fldy1" :style="'background-image: url('+webConfig.treatmentImg1+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[0].title" style="color: #ffffff">工作时间</label>
              <label v-show="testarr[0].content"  v-html="webConfig.treatmentText1">{{webConfig.treatmentText1}}</label>
            </div>
            <div class="fldy2" :style="'background-image: url('+webConfig.treatmentImg2+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[1].title" style="color: #ffffff">五险一金</label>
              <label v-show="testarr[1].content" v-html="webConfig.treatmentText2">{{webConfig.treatmentText2}}</label>
            </div>
            <div class="fldy3" :style="'background-image: url('+webConfig.treatmentImg3+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[2].title" style="color: #ffffff">带薪年假</label>
              <label v-show="testarr[2].content"  v-html="webConfig.treatmentText3">{{webConfig.treatmentText3}}</label>
            </div>
          </div>
          <div class="flex-row">
            <div class="fldy4" :style="'background-image: url('+webConfig.treatmentImg4+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[3].title" style="color: #ffffff">水吧福利</label>
              <label v-show="testarr[3].content"  v-html="webConfig.treatmentText4">{{webConfig.treatmentText4}}</label>
            </div>
            <div class="fldy5" :style="'background-image: url('+webConfig.treatmentImg5+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[4].title" style="color: #ffffff">年终福利</label>
              <label v-show="testarr[4].content"  v-html="webConfig.treatmentText5">{{webConfig.treatmentText5}}</label>
            </div>
            <div class="fldy6" :style="'background-image: url('+webConfig.treatmentImg6+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[5].title" style="color: #ffffff">员工关怀</label>
              <label v-show="testarr[5].content"  v-html="webConfig.treatmentText6">{{webConfig.treatmentText6}}</label>
            </div>
          </div>
          <div class="flex-row">
            <div class="fldy7" :style="'background-image: url('+webConfig.treatmentImg7+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[6].title" style="color: #ffffff">员工活动</label>
              <label v-show="testarr[6].content"  v-html="webConfig.treatmentText7">{{webConfig.treatmentText7}}</label>
            </div>
            <div style="width: 0.01rem"></div>
            <div class="fldy8" :style="'background-image: url('+webConfig.treatmentImg8+');'" @mouseover="mouseHandle" @mouseleave="mouseHandle">
              <label v-show="testarr[7].title" style="color: #ffffff">职业发展</label>
              <label v-show="testarr[7].content"  v-html="webConfig.treatmentText8">{{webConfig.treatmentText8}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="item4" class="flex-row row-center background-color-gray">
      <div class="main-content-width">
        <div class="title_img2">
          <img src="../assets/images/about/title_bghj.png"/>
        </div>
        <div class="bghj" v-if="webConfig.aboutSwiper.length>0">
          <!--<div class="jt"><img src="" @click="leftHandle"/></div>-->
          <!--<div><img id="bghj-zp" src="../assets/images/about/bghjzp.png"/></div>-->
          <!--<div class="jt"><img src="../assets/images/about/bghj_jt1.png" @click="rightHandle"/></div>-->

<!--//class="swiper-container" ref="mySwiper" -->
          <swiper class="swiper" :options="swiperOptions">

            <swiper-slide v-for="item in webConfig.aboutSwiper" :key="item.id"><img class="swiper_img" :src="item.imgUrl"/></swiper-slide>
            <!--<div class="swiper-pagination" slot="pagination"></div>-->
            <!--<div class="swiper-button-next"></div>-->
            <!--<div class="swiper-button-prev"></div>-->

          </swiper>
          <div class="btn_content">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>


  import 'swiper/css/swiper.min.css'
  import EventNameEnum from "../enum/EventName";

  import { swiper, swiperSlide,swiperWrapper } from 'vue-awesome-swiper'

   export default {
     created(){
       this.$event.$emit(EventNameEnum.changeBar,true);
       this.$http.get('webConfig/getWebConfig',{params: {type:3}},{})
               .then((res) => {
                 if(res.body.code==200){
                   // console.log(res.body.data)
                   for(let data of res.body.data){
                     if(data.attrType===4){
                       this.webConfig[data.name] = data.imgArr;
                     }else{
                       this.webConfig[data.name] = data.content;
                     }
                   }
                   // console.log("wwtest",this.webConfig)
                 }else{

                 }
               }, (error) => {
                 this.messaege.error("连接错误！");
               })
     },
     components: {
       swiper,
       swiperSlide,
     },
     data(){
       return{
          testarr:[
            {
              title:true,
              content:false,
            },
            {
              title:true,
              content:false,
            },{
              title:true,
              content:false,
            },
            {
              title:true,
              content:false,
            },{
              title:true,
              content:false,
            },{
              title:true,
              content:false,
            },{
              title:true,
              content:false,
            },{
              title:true,
              content:false,
            }
          ],
         swiperOptions:{
           effect:'coverflow',
           speed:1000,
           // flipEffect: {
           //   slideShadows : true,
           //   limitRotation : true,
           // },
           loop:true,
           navigation: {
             nextEl: ".swiper-button-next",
             prevEl: ".swiper-button-prev",
           },
         },
         webConfig:{
           introduction:"测测测测",
           cultureImg1:"",
           cultureImg2:"",
           cultureImg3:"",
           cultureImg4:"",
           treatmentImg1:"",
           treatmentImg2:"",
           treatmentImg3:"",
           treatmentImg4:"",
           treatmentImg5:"",
           treatmentImg6:"",
           treatmentImg7:"",
           treatmentImg8:"",
           treatmentText1:"",
           treatmentText2:"",
           treatmentText3:"",
           treatmentText4:"",
           treatmentText5:"",
           treatmentText6:"",
           treatmentText7:"",
           treatmentText8:"",
           aboutSwiper:[],
         }
       }
     },
     methods:{
       mouseHandle(e){
         if(e.target.className.slice(0,4)!="fldy")return;
         let index= Number(e.target.className.slice(4,5))-1;
         // console.log(index);
         if(e.type=="mouseover"){
           // console.log(e.type)
           this.testarr[index].content = true;
         }else{
           this.testarr[index].content = false;
         }
       },

     }
   }
</script>

<style scoped>

  .btn_content{
    position: absolute;
    width: 120rem;
    height: 60rem;
    margin: 0 auto;
  }

  .title_img2 img{
    margin-top: 4.4rem;
    margin-bottom: 1rem;
  }

  .swiper {
  width: 101.6rem;
  /*height: 300px;*/
  /*padding: 5rem;*/
    margin: 5rem auto;
  }

  /*.swiper-slide img {*/
    /*display: block;*/
    /*width: 101.6rem;*/
  /*}*/

  /*.swiper-slide {*/
  /*background-position: center;*/
  /*background-size: cover;*/
  /*width: 101.6rem;*/
  /*!*height: 300px;*!*/

  /*}*/

  .swiper-container .swiper-slide{
    width: 100%;
    line-height: 20rem;
    color: #000;
    text-align: center;
    height: 57.1rem;
  }

  .row-center {
    justify-content: center;
  }

  .background-color-gray{
    background-color: #F3F3F3;
  }


  .about-guide-bar{
    display: flex;
    flex-direction: column;
    background-color:#4C4C4C;
    position: fixed;
    bottom:0rem;
    right:0rem;
    z-index: 100;
    width: 20rem;
    height: 31.2rem;
  }
  .about-guide-bar a:first-child{
    margin-top: 2.2rem;
  }
  .about-guide-bar a{
    display: block;
    line-height: 6.7rem;
    height:6.7rem;
    width: 19.8rem;
    font-size: 2.4rem;
    color: #ffffff;
    text-decoration: none;
    font-family: "微软雅黑";
  }

  .about-guide-bar a:hover{
    background-color: #f66320;
  }

  .company-introduction-text{
    display:inline-block;
    font-family: "微软雅黑";
    font-size: 2.4rem;
    line-height: 3.6rem;
    width: 119.5rem;
    text-indent:4.8rem;
    text-align: left;
  }

  .qywh{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .qywh div{
    width: 120rem;
    padding-left: 2rem;
  }

  .fldy{
    width: 75rem;
    margin: 2rem auto 2rem;
  }
  .fldy div{
    padding-top: 1rem;
    margin: 0 auto;
  }

  .fldy div div{
    height: 18.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fldy div div label:first-child{
    color:#ffffff;
    font-size: 2.3rem;
    display: block;
    margin: 0 auto;
    line-height: 2.5rem;
    /*padding-bottom: 1rem;*/
  }

  .fldy div div label:last-child{
    color:#ffffff;
    font-size: 1.5rem;
    display: block;
    margin: 0 auto;
    line-height: 1.6rem;
    padding-bottom: 1rem;
  }

  .fldy1{
    /*background-image: url("../assets/images/about/gsfl1.png");*/
    width: 18.5rem;
  }

  .fldy2{
    /*background-image: url("../assets/images/about/gsfl2.png");*/
    width: 22.5rem;
  }

  .fldy3{
    /*background-image: url("../assets/images/about/gsfl3.png");*/
    width: 30.3rem;
  }

  .fldy4{
    /*background-image: url("../assets/images/about/gsfl4.png");*/
    width: 30.3rem;
  }

  .fldy5{
    /*background-image: url("../assets/images/about/gsfl5.png");*/
    width: 23.2rem;
  }

  .fldy6{
    /*background-image: url("../assets/images/about/gsfl6.png");*/
    width: 17.4rem;
  }

  .fldy7{
    /*background-image: url("../assets/images/about/gsfl7.png");*/
    width: 26.9rem;
  }

  .fldy8{
    /*background-image: url("../assets/images/about/gsfl8.png");*/
    width: 44.8rem;
  }

  .bghj{
    /*padding-top: 2rem;*/
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 120rem;
  }

  .swiper-wrapper{
    /* 通过改变animation-timing-function 制作弹性切换效果 */
    transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s!important;
  }

  .swiper-button-next {
    border-radius: 0rem;
    right: -5rem;
    left: auto;
    height: 9.2rem!important;
    width: 3.4rem!important;
    color: rgba(0, 0, 0, 0)!important;
    background:url("../assets/images/about/bghj_jt1.png") center center no-repeat;
    /*background-size: 30px 30px;*/
  }
  .swiper-button-prev{
    border-radius: 0rem;
    left: -5rem;
    right: auto;
    height: 9.2rem!important;
    width: 3.4rem!important;
    color: rgba(0, 0, 0, 0)!important;
    background:url("../assets/images/about/bghj_jt2.png") center center no-repeat;
    /*background-size: 30px 30px;*/
  }

  /*.jt{*/
    /*padding-right: 2rem;*/
    /*padding-left: 2rem;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
  /*}*/

  /*#bghj-zp{*/
     /*width: 101.7rem;*/
     /*height: 57.1rem;*/
  /*}*/

  .swiper_img{
    max-width: 101.6rem;
    max-height: 57.1rem;
    margin: 0 auto;
    object-fit: cover;
  }

</style>
