<template>
    <div class="flex-col">

        <div id="productselect " style="padding-top: 4rem;margin-bottom: 1rem" >
            <el-radio-group v-model="radioType" :fill="'#f66320'" :text-color="'#ffffff'" @change="radioClickHandle">
                <div class="flex-row row-center productbuttons" >
                    <div><el-radio-button class="radius-button" id="hireradio1" label="1"><span :style="{color:radioType=='1'?'#ffffff':'#f66320'}">手游</span></el-radio-button></div>
                    <div><el-radio-button class="radius-button" id="hireradio2" label="2"><span :style="{color:radioType=='2'?'#ffffff':'#f66320'}">H5</span></el-radio-button></div>
                    <div><el-radio-button class="radius-button" id="hireradio3" label="3"><span :style="{color:radioType=='3'?'#ffffff':'#f66320'}">其他</span></el-radio-button></div>
                </div>
            </el-radio-group>
        </div>

        <div style="width: 100%;margin: 0 auto">
            <div class="productcontent" v-if="boxTable1.length>0">
                <router-link
                        :to="{path:'/product/productArticle',query:{id:item.id}}"
                        style="text-decoration: none"
                        v-for="item in boxTable1"
                        :key="item.id"
                >
                    <div class="productbox">
                        <div><span class="title">{{item.title}}</span></div>
                        <div><img :src="item.logoUrl" class="product-img"></div>
                        <div></div>
                    </div>
                </router-link>
            </div>

            <div class="productcontent" v-if="boxTable2.length>0">
                <router-link
                        :to="{path:'/product/productArticle',query:{id:item.id}}"
                        style="text-decoration: none"
                        v-for="item in boxTable2"
                        :key = "item.id"
                >
                    <div class="productbox">
                        <div><span class="title">{{item.title}}</span></div>
                        <div><img :src="item.logoUrl" class="product-img"></div>
                        <div></div>
                    </div>
                </router-link>
            </div>
        </div>

        <div id="productpage" style="padding-top: 6rem;padding-bottom: 2rem">
            <el-pagination  @current-change="changePageHandle"
                            layout="prev, pager, next"
                            :page-size="pageConfig.size"
                            :total="pageConfig.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import EventNameEnum from "../enum/EventName";
    export default {
        name: "Product.vue",
        created(){
            this.$event.$emit(EventNameEnum.changeBar,true);

            if(this.$route.query.type){
                this.radioType = `${this.$route.query.type}`;
            }
            this.fullIndex();
            this.fullTabel();
        },
        data(){
          return{
              radioType:'1',
              boxTable1:[],
              boxTable2:[],
              pageConfig:{
                  index:1,
                  size:6,
                  total:500,
              }
          }
        },
        methods:{
            radioClickHandle:function (e) {
                //修改刷新当前目录目录
                // console.log("radio:"+e)
                this.pageConfig.index = 1;
                this.fullIndex();
                this.fullTabel();
            },
            changePageHandle:function (e) {
                // console.log("page:"+e)
                this.pageConfig.index = e;
                this.fullTabel();
            },
            fullTabel(){
                this.$http.post('product/getProductByIndex', {type:this.radioType,index:this.pageConfig.index},{}).then((res) => {
                    if(res.body.code==200){
                        // console.log(res.body.data)
                        this.boxTable1 = [];
                        this.boxTable2 = [];
                        let boxTable = res.body.data;
                        for(let i=0;i<boxTable.length;i++){
                            if(i<3){
                                this.boxTable1.push(boxTable[i]);
                            }else{
                                this.boxTable2.push(boxTable[i]);
                            }
                        }
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            fullIndex(){
                this.$http.get('product/getTotal',{params: {type:this.radioType}},{}).then((res) => {
                    if(res.body.code==200){
                        this.pageConfig.total=res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            dateFormat(time){
                let timer = new Date(time);
                return timer.getFullYear()+"."+(timer.getMonth()+1)+"."+timer.getDate();
            }
        },
        activated() {

        }
    }
</script>

<style>

    .productbuttons div{
        height: 6.3rem;
        padding-top: 3rem;
        padding-right: 3rem;
    }

    .productbuttons div .el-radio-button__inner{
        height: 6.3rem;
        width: 20.6rem;
        border-radius: 4rem !important;
        padding-top: 0;
        border: .1rem solid #f66320 !important;
    }

    .productbuttons div .el-radio-button__inner span{
        display: inline-block;
        line-height: 6.3rem;
        font-family: "微软雅黑";
        font-weight: bold;
        font-size: 1.9rem;
        color:#f66320;
        text-align: center;
    }

    .productcontent{
        height: 33.5rem;
        width: 130rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 相当于 1fr 1fr 1fr */
        grid-template-rows: repeat(1, 1fr); /* fr单位可以将容器分为几等份 */
        /*grid-gap: 1%; !* grid-column-gap 和 grid-row-gap的简写 *!*/
        grid-auto-flow: row;
        /*background-color: #39b54a;*/
        padding-top: 6rem;
        margin: 0 auto;
    }

    .productbox{
        padding-top: .5rem;
        height: 30.5rem;
        width: 38.8rem;
        border-radius: 1rem;
        box-shadow: darkgrey 0rem 0rem 1rem 0.1rem; /*边框阴影*/
    }

    .productbox div:first-child{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 35.5rem;
    }

    .productbox div:first-child .title{
        color: black;
    }

    .productbox div:last-child{
        text-align: right;
        width: 35.5rem;
    }

    .productbox div{

        margin: 1rem auto 0 auto;
    }

    .productbox .title{
        color: #717171;
        font-family: "微软雅黑";
        font-weight: bold;
        font-size: 1.62rem;
    }

    .productbox .time{
        color: #c5c5c5;
        font-family: "微软雅黑";
        font-weight: bold;
        font-size: 1.08rem;
    }

    .product-img{
        height: 21.8rem;
        width: 38.8rem;
    }

    .product-btn{
        font-size: 1.1rem;
        font-family: "微软雅黑";
        color: #f66320;
        font-weight: bold;
        border: 0.2rem solid #f66320;
        background: #FFFFFF;
        border-radius: 3rem;
        padding: .5rem 2.3rem;
    }

    .product-btn:hover{
        background-color: #f66320;
        color:#ffffff ;
    }


    #productpage .el-pagination .el-pager li.active{
        background-color:#f66320;
        color:#ffffff;
    }
</style>
