<template>
    <div class="main">
        <div class="bgDiv">
            <img :style="`width: ${item.bgWidth}rem;aspect-ratio: auto ${item.bgWidth} / ${item.bgHeight};height: ${item.bgHeight}rem;`"
                 src="../assets/images/product/bg.png"
                 v-if="!item.bgUrl">
            <img :style="`width: ${item.bgWidth}rem;aspect-ratio: auto ${item.bgWidth} / ${item.bgHeight};height: ${item.bgHeight}rem;`"
                 :src="item.bgUrl"
                 v-if="item.bgUrl">
        </div>

        <div class="content">
            <div>
                <img v-if="item.imgUrl&&item.imgUrl.length!=0" :src="item.imgUrl" style="object-fit:none">
                <span v-if="!item.imgUrl||item.imgUrl.length==0" class="mainTitle">{{item.title}}</span>
            </div>
            <div><span class="title">丨发布时间：</span><p>{{item.pushTime}}</p></div>
            <div><span class="title">丨类&ensp;&ensp;&ensp;&ensp;型：</span><p>{{item.gameType}}</p></div>
            <div><span class="title">丨简&ensp;&ensp;&ensp;&ensp;介：</span><p>{{item.content}}</p></div>
            <div v-if="isdownLinkVisable(item.downLink)"><span class="title">丨下载游戏：</span><p><a :href="item.downLink">请点击这里下载</a></p></div>
            <div><router-link class="backBtn" :to='{path:"/product",query:{type:item.type}}' ><el-button class="" type="info" round>返回</el-button></router-link></div>
        </div>
    </div>
</template>

<script>
    import EventNameEnum from "../enum/EventName";
    export default {
        name: "ProductArticle",
        created(){
            this.$event.$emit(EventNameEnum.changeBar,false);
            this.initProductArt(this.$route.query.id);
        },
        data(){
            return{
                item:{},
            }
        },
        methods:{
            initProductArt:function(id){
                this.$http.get('product/getArticle',{params:{id:id}},{}).then((res) => {
                    // console.log("sdsds",res)
                    if(res.body.code==200){
                        // console.log(res.body.data);
                        this.item=res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                });
            },
            isdownLinkVisable:function(link){
                if(!link||link.toString().trim().length===0){
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style scoped>
    .main{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /*padding-top: 8rem;*/
    }

    .content{
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .content div{
        margin: 1rem auto 0 0;
        width: 41rem;
        text-align: start;
        display: flex;
        flex-direction: row;
        font-size: 1.8rem;
        font-family: "微软雅黑";
    }

    .content div span{
        display: inline-block;
        width: 11rem;
    }

    .content div p {
        margin: 0;
        width: 30rem;
        word-wrap: break-word;
    }

    .mainTitle{
        font-family: "微软雅黑";
        font-size: 2.5rem;
        color: black;
    }

    .bgDiv{
        height: 100rem;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .bgDiv img{
        margin-left: auto;
        margin-right: auto;
    }

    .backBtn{
        width: 10rem;
        margin-left: auto;
        margin-right: 0;
    }
</style>
