<template>
    <div class="flex-col row-center">
        <div>
            <div id="mapDiv" class="bm-view">
                <!--            <baidu-map class="bm-view" ak="2YMXZf1BgRRlVMGtouhUIyrnaHIGsfRE" :center="{lng: webConfig.mapLng, lat: webConfig.mapLat}" :zoom="zoom" @ready="handler">-->

                <!--                <bm-marker :position="{lng: webConfig.companyLng, lat: webConfig.companyLat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>-->

                <!--                <bm-info-window :position="{lng: webConfig.companyLng, lat: webConfig.companyLat}" title="我们的位置" :show="infoWindow.show" @close="infoWindowClose" @open="infoWindowOpen">-->
                <!--                    <p>公司电话：{{webConfig.companyTel}}</p>-->
                <!--                    <p>邮政编码：{{webConfig.postalCode}}</p>-->
                <!--                    <p>公司地址：{{webConfig.address}}</p>-->
                <!--                    &lt;!&ndash;<button @click="clear">Clear</button>&ndash;&gt;-->
                <!--                </bm-info-window>-->
                <!--            </baidu-map>-->

            </div>
        </div>
        <div class="max-width">
            <div class="title-img">
                <img src="../assets/images/contact/title_lxwm.png"/>
            </div>
            <div class="flex-row row-center max-width contact-message">
                <label>公司地址：{{webConfig.linkAddress}}</label>
                <label>公司邮箱：{{webConfig.email}}</label>
                <label>公司电话：{{webConfig.linkphone}}</label>
            </div>
            <div class="contact-img flex-row row-center">
                <div>
                    <img src="../assets/images/contact/dlsq.png"/>
                </div>
                <div>
                    <img src="../assets/images/contact/hwhz.png"/>
                </div>
                <div>
                    <img src="../assets/images/contact/schz.png"/>
                </div>
                <div>
                    <img src="../assets/images/contact/swhz.png"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //百度地图
    // import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    // import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow'
    // import BmMarkerClusterer from  'vue-baidu-map/components/extra/MarkerClusterer'
    // import BmMarker from 'vue-baidu-map/components/overlays/Marker'

    import EventNameEnum from "../enum/EventName";

    export default {
        name: "Contact",
        components: {
            // BaiduMap,
            // BmInfoWindow,
            // BmMarkerClusterer,
            // BmMarker,
        },
        created() {
            this.$event.$emit(EventNameEnum.changeBar, false);
            // this.$http.get('webConfig/getWebConfig', {params: {type: 4}}, {})
            //     .then((res) => {
            //         if (res.body.code == 200) {
            //             // console.log(res.body.data)
            //             for (let data of res.body.data) {
            //                 this.webConfig[data.name] = data.content;
            //             }
            //             // console.log("wwtest",this.webConfig)
            //         } else {
            //
            //         }
            //     }, (error) => {
            //         this.messaege.error("连接错误！");
            //     })
        },
        data() {
            return {
                center: {lng: 119.261576, lat: 26.067957},
                zoom: 25,
                infoWindow: {
                    show: true,
                    contents: "你猜一猜我们在哪里啊？"
                },
                webConfig: {
                    email: "",
                    linkAddress: "",
                    linkphone: "",
                    companyTel: "",
                    postalCode: "",
                    address: "",
                    mapLng: "119.2503",//"119.261576",
                    mapLat: "26.0652",//"26.067957",
                    companyLng: "119.2503",//百度："119.261576",天地图："119.2503"
                    companyLat: "26.0650",//百度："26.067957",天地图："26.0650"
                    Tconfig: {
                        lng: "119.2503",
                        lat: "26.0650"
                    }
                },
                map: null //地图原型
            }
        },
        mounted() {
            this.$http.get('webConfig/getWebConfig', {params: {type: 4}}, {})
                .then((res) => {
                    if (res.body.code == 200) {
                        // console.log(res.body.data)
                        for (let data of res.body.data) {
                            this.webConfig[data.name] = data.content;
                        }
                        this.initMap();
                    } else {

                    }
                }, (error) => {
                    this.initMap();
                    this.messaege.error("连接错误！");
                })
            // this.initMap()
        },
        methods: {
            // handler ({BMap, map}) {
            //     // console.log(BMap, map)
            //     this.center.lng = 119.261576
            //     this.center.lat = 26.067957
            //     this.zoom = 25//119.261576,26.067957
            // },
            infoWindowClose(e) {
                this.infoWindow.show = false
            },
            infoWindowOpen(e) {
                this.infoWindow.show = true
            },
            clear() {
                this.infoWindow.contents = ''
            },
            initMap() {
                let T = window.T;
                this.map = new T.Map('mapDiv'); // 地图实例
                this.map.centerAndZoom(new T.LngLat(this.webConfig.mapLng, this.webConfig.mapLat), 18);
                let point = new T.LngLat(this.webConfig.companyLng, this.webConfig.companyLat);
                let marker = new T.Marker(point);// 创建标注
                this.map.addOverLay(marker);
                let markerInfoWin = new T.InfoWindow(
                    "<p>我们的位置</p>" +
                    `<p>公司电话：${this.webConfig.companyTel}</p>\n` +
                    `<p>邮政编码：${this.webConfig.postalCode}</p>\n` +
                    `<p>公司地址：${this.webConfig.address}</p>`);
                marker.openInfoWindow(markerInfoWin);
                // marker.addEventListener("click", function () {
                //     marker.openInfoWindow(markerInfoWin);
                // });// 将标注添加到地图中

            }
        }
    }
</script>

<style scoped>

    .bm-view {
        width: 100%;
        height: 60rem;
    }

    .row-center {
        justify-content: center;
    }

    .auto-width {
        margin: 0 auto;
    }

    .max-width {
        width: 100%;
    }

    .contact-message {
        background-color: #efefef;
        height: 5.7rem;
    }

    .contact-message label {
        display: inline-block;
        padding-left: 10rem;
        padding-right: 10rem;
        line-height: 5.7rem;
        height: 5.7rem;
        font-family: "微软雅黑";
        font-size: 2.0rem;
        font-weight: bold;
    }

    .row-center {
        justify-content: center;
    }

    .contact-img {
        padding-top: 4rem;
        padding_bottom: 4rem;
        background-color: #f9f9f9;
    }

    .contact-img div {
        padding-left: 1rem;
        padding-right: 1rem;
    }
</style>
