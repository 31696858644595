<template>
<div class="flex-col">
    <div class="newscontent" v-if="boxTable1.length>0">
        <router-link
                :to="{path:'/news/newsArticle',query:{id:item.id}}"
                v-for="item in this.boxTable1"
                style="text-decoration: none"
                :key="item.id"
        >
            <div class="newbox">
                <div><img :src="item.imgUrl" class="newbox-img"></div>
                <div class="timeAndType">
                    <span>{{switchTypeName(item.type)}}</span>
                    <span>{{dateFormat(item.createAt)}}</span>
                </div>
                <div class="title"><span >{{item.title}}</span></div>
            </div>
        </router-link>
    </div>

    <div class="newscontent" v-if="boxTable2.length>0">
        <router-link
                :to="{path:'/news/newsArticle',query:{id:item.id}}"
                v-for="item in this.boxTable2"
                style="text-decoration: none"
                :key="item.id"
        >
            <div class="newbox">
                <div><img :src="item.imgUrl" class="newbox-img"></div>
                <div class="timeAndType">
                    <span>{{switchTypeName(item.type)}}</span>
                    <span>{{dateFormat(item.createAt)}}</span>
                </div>
                <div class="title"><span >{{item.title}}</span></div>
            </div>
        </router-link>
    </div>

    <div id="newspage" style="padding-top: 6rem; padding-bottom: 2rem">
        <el-pagination  @current-change="changePageHandle"
                        layout="prev, pager, next"
                        :page-size="pageConfig.size"
                        :total="pageConfig.total">
        </el-pagination>
    </div>
</div>
</template>

<script>
    import EventNameEnum from "../enum/EventName";
    export default {
        name: "News",
        created(){
            this.$event.$emit(EventNameEnum.changeBar,true);
            this.fullIndex();
            this.fullTabel();
        },
        data(){
            return{
                pageConfig:{
                    index:1,
                    size:6,
                    total:500,
                },
                boxTable1:[],
                boxTable2:[]
            }
        },
        methods:{
            changePageHandle:function (e) {
                // console.log("page:"+e)
            },
            fullTabel(){
                this.$http.post('news/getNewsByIndex', {index:this.pageConfig.index},{}).then((res) => {
                    if(res.body.code==200){
                        // console.log(res.body.data)
                        this.boxTable1 = [];
                        this.boxTable2 = [];
                        let boxTable = res.body.data;
                        for(let i=0;i<boxTable.length;i++){
                            if(i<3){
                                this.boxTable1.push(boxTable[i]);
                            }else{
                                this.boxTable2.push(boxTable[i]);
                            }
                        }
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            fullIndex(){
                this.$http.get('news/getTotal',{},{}).then((res) => {
                    if(res.body.code==200){
                        this.pageConfig.total=res.body.data;
                    }else{
                        // console.warn(res.body.message);
                    }
                }, (error) => {
                    this.messaege.error("连接错误！");
                })
            },
            dateFormat(time){
                let timer = new Date(time);
                return timer.getFullYear()+"."+(timer.getMonth()+1)+"."+timer.getDate();
            },
            switchTypeName(type) {
                switch (type) {
                    case 1:
                        return "公司动态";
                    case 2:
                        return "产品动态";
                    case 3:
                        return "行业资讯";
                    default:
                        return "未知";
                }
            }
        }
    }
</script>

<style>
    .newscontent{
        height: 34.5rem;
        width: 130rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 相当于 1fr 1fr 1fr */
        grid-template-rows: repeat(1, 1fr); /* fr单位可以将容器分为几等份 */
        /*grid-gap: 1%; !* grid-column-gap 和 grid-row-gap的简写 *!*/
        grid-auto-flow: row;
        /*background-color: #39b54a;*/
        padding-top: 6rem;
        margin: 0 auto 1rem auto;
    }

    .newbox{
        padding-top: 2rem;
        height: 33rem;
        width: 38.8rem;
        border-radius: 1rem;
        box-shadow: darkgrey 0rem 0rem 1rem 0.1rem; /*边框阴影*/
    }

    .newbox div:first-child{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }


    .newbox div{
        width: 35.5rem;
        margin: 1rem auto 0 auto;
    }

    .newbox .time{
        color: #c5c5c5;
        font-family: "微软雅黑";
        font-weight: bold;
        font-size: 1.08rem;
        text-align: right;
    }

    .newbox-img{
        height: 20rem;
        width: 35.5rem;
    }

    #newspage .el-pagination .el-pager li.active{
        background-color:#f66320;
        color:#ffffff;
    }
</style>

<style>
    .newDesc{
        color:#717171;
    }

    .title{
        color: #000000;
        font-family: "微软雅黑";
        font-weight: bold;
        font-size: 1.62rem;
        text-align: left !important;
    }

    .timeAndType{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #E1E2DF;
        color: #000000;
        line-height: 2rem;
        padding-right: .5rem;
        padding-left: .5rem;
    }
</style>
